import React, { useState } from "react";
import SearchBar from "./SearchBar"
import Filter from "./Filter"
import PurchaseList from "./PurchaseList";
import Price from "./Price";

const MobileEstimateHandler = ({ partSelect, selectedList, cancelSelect, getCancelSelect, getIsDivide, isDivide, setSearchKeyword, isMobile }) => {
    const [isFold, setIsFold] = useState(false)
    const foldHandler = () => {
        setIsFold(!isFold)
    }

    return (
        <div className={ `estimate-handler-wrapper-${isFold ? "unfold" : "fold"}` }>
            <div className="estimate-handler-button">
                <button onClick={ () => foldHandler() }>커스텀 정보</button>
            </div>
            <div className="estimate-handler-content" style={{display: isFold ? "block" : "none"}}>
                <SearchBar
                    setSearchKeyword={ setSearchKeyword }
                />
                <Filter 
                    partSelect={ partSelect }
                    getIsDivide={ getIsDivide }
                />
                <PurchaseList
                    selectedList={ selectedList }
                    getCancelSelect={ getCancelSelect }
                    isMobile={ isMobile }
                    isDivide={ isDivide }
                />
                <Price
                    selectedList={ selectedList }
                    cancelSelect={ cancelSelect }
                    isDivide={ isDivide }
                />
            </div>
        </div>
    )
}

export default MobileEstimateHandler