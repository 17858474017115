import React, { useState, useEffect } from "react";
import "./Components.css"
import { debounce } from "lodash";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width < 768;

    const handleResize = debounce(() => {
        setWidth(window.innerWidth);
    }, 200);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize); // cleanup
        };
    }, []);

    return (
        <div className="header">
            <div className="login-menu">
                <div>로그인</div>
                <div>회원가입</div>
            </div>
            <div className="logo">
                <img alt="logo" src={ isMobile ? `${process.env.PUBLIC_URL}/BiF_mobile_logo.png` : `${process.env.PUBLIC_URL}/BiF_browser_logo.png` }/>
            </div>
            <div className="menu">
                견적
            </div>
        </div>
    )
}

export default Header