import React from "react";
import "./Estimate.css"

const ProductPreview = ({ id, img_src, product_name, simple_explain, product_price, seatpost_position, stem_Xposition, stem_Yposition, getProductSelect }) => {
    const postProductSelect = () => {
        // console.log(stem_Xposition)

        getProductSelect({
            "id": id,
            "img_src": img_src,
            "product_name": product_name,
            "product_price": product_price,
            "seatpost_position": seatpost_position,
            "stem_Xposition": stem_Xposition,
            "stem_Yposition": stem_Yposition
        })
    }

    return (
        <div className="preview-wrapper" onClick={ postProductSelect }>
            <div className="thumbnail">
                <img src={ img_src }/>
            </div>
            <div className="product-explain">
                <div className="product-name">
                    { product_name }
                </div>
                <div className="simple-explain">
                    { simple_explain }
                </div>
                <div className="product-price">
                    { product_price.toLocaleString("ko-KR") } 원
                </div>
            </div>
        </div>
    )
}

export default ProductPreview