import { useEffect, useState } from "react";
import "./Estimate.css"
import ProductPreview from "./ProductPreview";
import axios from "axios"
import SearchBar from "./SearchBar";
import PageBar from "./PageBar"

const ProductList = ({ partSelect, getProductSelect, searchKeyword, setSearchKeyword, isMobile }) => {
    const [productData, setProductData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        // console.log(partSelect)

        const getProductData = () => {
            let tempPartSelect = partSelect
            if (tempPartSelect === "front_wheel" || tempPartSelect === "rear_wheel") {
                tempPartSelect = "wheelset"
            }
            axios.get(`${process.env.REACT_APP_API_PARTS}/part`, {
                params: { part: tempPartSelect }
            })
            .then((res) => { setProductData(res.data) })
        }

        getProductData()

        // console.log(productData)
    }, [partSelect])

    useEffect(() => {
        productData.sort((a, b) => {
            if (a.product_name < b.product_name) return -1
            if (a.product_name > b.product_name) return 1
            return 0
        })

        if (searchKeyword) {
            let tempFilteredData = []
            productData.forEach(element => {
                if (element.product_name.includes(searchKeyword)) {
                    tempFilteredData.push(element)
                }
            });
            setFilteredData(tempFilteredData)
            
            // console.log(filteredData)
        } else {
            setFilteredData(productData)

            // console.log(filteredData)
        }
    }, [searchKeyword, productData])

    return (
        <>
            {
                isMobile
                ? <></>
                :
                <SearchBar
                    setSearchKeyword={ setSearchKeyword }
                />
            }
            <div className="product-list">
                {
                    filteredData
                    ? filteredData.map((data, i) => (
                        <ProductPreview
                            key={ i }
                            id={ data._id }
                            img_src={ data.img_src }
                            product_name={ data.product_name }
                            simple_explain={ data.simple_explain }
                            product_price={ data.product_price }
                            seatpost_position={ data.seatpost_position }
                            stem_Xposition={ data.stem_Xposition }
                            stem_Yposition={ data.stem_Yposition }
                            getProductSelect={ getProductSelect }
                        />
                    ))
                    : <p style={{ marginLeft: "16px" }}>데이터가 존재하지 않습니다</p>
                }
            </div>
            {
                // isMobile
                // ? <></>
                // : <PageBar />
            }
        </>
    )
}

export default ProductList