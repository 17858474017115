import React from "react";
import "./Estimate.css"

const Price = ({ selectedList, isDivide }) => {
    const total_price = () => {
        let sum = 0
        for (const part in selectedList) {
            sum += selectedList[part].product_price
        }
        if (isDivide === false) sum -= selectedList.front_wheel.product_price
        return sum
    }

    return (
        <div className="price">
            총액 : { typeof(total_price()) === "number" ? total_price().toLocaleString("ko-KR") : 0 } 원
        </div>
    )
}

export default Price