import React, { useEffect } from "react";
import "./Estimate.css"

const PurchaseList = ({ selectedList, getCancelSelect, isMobile, isDivide }) => {
    const a = selectedList
    
    const postCancelSelect = (partSelect) => {
        getCancelSelect(partSelect)
    }
    
    useEffect(() => {
        if (isDivide === false) getCancelSelect("wheelset")
    }, [isDivide])

    return (
        <div className="purchase-list">
            <div className="purchase-list-title">
                커스텀목록
            </div>
            <div className="purchase-list-body">
                <table>
                    <colgroup>
                        {
                            isMobile
                            ? 
                            <>
                                <col width={"25%"}/>
                                <col width={"*"}/>
                                <col width={"30%"}/>
                                <col width={"24px"}/>
                            </>
                            :
                            <>
                                <col width={"10%"}/>
                                <col width={"*"}/>
                                <col width={"15%"}/>
                                <col width={"24px"}/>
                            </>
                        }
                    </colgroup>
                    <tbody>
                        {
                            isDivide
                            ?
                            <>
                                <tr>
                                    <td>프론트휠 :</td>
                                    <td>{ a.front_wheel.product_name }</td>
                                    <td>{ a.front_wheel.product_price && <p>{ a.front_wheel.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                                    <td>
                                        {
                                            a.front_wheel.product_name
                                            ? <button onClick={ () => postCancelSelect("front_wheel") }>X</button>
                                            : <></>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>리어휠 :</td>
                                    <td>{ a.rear_wheel.product_name }</td>
                                    <td>{ a.rear_wheel.product_price && <p>{ a.rear_wheel.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                                    <td>
                                        {
                                            a.rear_wheel.product_name
                                            ? <button onClick={ () => postCancelSelect("rear_wheel") }>X</button>
                                            : <></>
                                        }
                                    </td>
                                </tr>
                            </>
                            :
                            <tr>
                                <td>휠셋 :</td>
                                <td>{ a.front_wheel.product_name }</td>
                                <td>{ a.front_wheel.product_price && <p>{ a.front_wheel.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                                <td>
                                    {
                                        a.front_wheel.product_name
                                        ? <button onClick={ () => postCancelSelect("wheelset") }>X</button>
                                        : <></>
                                    }
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>프레임 :</td>
                            <td>{ a.frame.product_name }</td>
                            <td>{ a.frame.product_price && <p>{ a.frame.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.frame.product_name
                                    ? <button onClick={ () => postCancelSelect("frame") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>싯포스트 :</td>
                            <td>{ a.seatpost.product_name }</td>
                            <td>{ a.seatpost.product_price && <p>{ a.seatpost.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.seatpost.product_name
                                    ? <button onClick={ () => postCancelSelect("seatpost") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>안장 :</td>
                            <td>{ a.saddle.product_name }</td>
                            <td>{ a.saddle.product_price && <p>{ a.saddle.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.saddle.product_name
                                    ? <button onClick={ () => postCancelSelect("saddle") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>스템 :</td>
                            <td>{ a.stem.product_name }</td>
                            <td>{ a.stem.product_price && <p>{ a.stem.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.stem.product_name
                                    ? <button onClick={ () => postCancelSelect("stem") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>핸들바 :</td>
                            <td>{ a.handlebar.product_name }</td>
                            <td>{ a.handlebar.product_price && <p>{ a.handlebar.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.handlebar.product_name
                                    ? <button onClick={ () => postCancelSelect("handlebar") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>체인링 :</td>
                            <td>{ a.chainring.product_name }</td>
                            <td>{ a.chainring.product_price && <p>{ a.chainring.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.chainring.product_name
                                    ? <button onClick={ () => postCancelSelect("chainring") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>크랭크 :</td>
                            <td>{ a.crank.product_name }</td>
                            <td>{ a.crank.product_price && <p>{ a.crank.product_price.toLocaleString("ko-KR") } 원</p> }</td>
                            <td>
                                {
                                    a.crank.product_name
                                    ? <button onClick={ () => postCancelSelect("crank") }>X</button>
                                    : <></>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PurchaseList