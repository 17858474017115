import React from "react";

const ProductInputForm = ({ handleChange, handleSubmit, formData }) => {
    return (
        <div style={{ width: "400px", height: "1400px"}}>
            <h1>Product Input Form</h1>
                <form onSubmit={handleSubmit}>
                <div>
                    <h3>Part</h3>
                    <input
                    type="text"
                    name="part"
                    value={formData.part}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div>
                    <h3>Image Source URL</h3>
                    <input
                    type="text"
                    name="img_src"
                    value={formData.img_src}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div>
                    <h3>Product Name</h3>
                    <input
                    type="text"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div>
                    <h3>Simple Explanation</h3>
                    <textarea
                    name="simple_explain"
                    value={formData.simple_explain}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div>
                    <h3>Product Price</h3>
                    <input
                    type="number"
                    name="product_price"
                    value={formData.product_price}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div>
                    <h3>Brand</h3>
                    <input
                    type="text"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    required
                    />
                </div>

                {/* Optional fields */}
                <div>
                    <h3>Size</h3>
                    <input
                    type="number"
                    name="size"
                    value={formData.size}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Length</h3>
                    <input
                    type="number"
                    name="length"
                    value={formData.length}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Rim Height</h3>
                    <input
                    type="number"
                    name="rim_height"
                    value={formData.rim_height}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Material</h3>
                    <input
                    type="text"
                    name="material"
                    value={formData.material}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Type</h3>
                    <input
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>T Number</h3>
                    <input
                    type="number"
                    name="t_num"
                    value={formData.t_num}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Seatpost position</h3>
                    <input
                    type="number"
                    name="seatpost_position"
                    value={formData.seatpost_position}
                    onChange={handleChange}
                    />
                </div>

                <div>
                    <h3>Stem X position</h3>
                    <input
                    type="number"
                    name="stem_Xposition"
                    value={formData.stem_Xposition}
                    onChange={handleChange}
                    />
                </div>
                
                <div>
                    <h3>Stem Y position</h3>
                    <input
                    type="number"
                    name="stem_Yposition"
                    value={formData.stem_Yposition}
                    onChange={handleChange}
                    />
                </div>
                <button type="submit" style={{ marginTop: "20px", padding: "10px 15px", backgroundColor: "#28a745", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>
                    Submit
                </button>
            </form>
        </div>
    )
}

export default ProductInputForm