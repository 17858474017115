import React from "react";
import "./Estimate.css"

const Final = () => {
    return (
        <div className="final">
            <button>구매하기</button>
            <button>호환성 체크</button>
        </div>
    )
}

export default Final