import React, { useEffect, useState } from "react";
import axios from "axios"

const AllProductList = ({ selectFormData }) => {
    const [partDatas, setPartDatas] = useState([])
    const [refresh, setRefresh] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PARTS}/all`)
            .then((res) => { setPartDatas(res.data) })

        // console.log(partDatas)
    }, [refresh])

    const selectProduct = async(partId) => {
        // console.log(partId)

        axios.get(`${process.env.REACT_APP_API_PARTS}/one`, {
            params: { partId: partId }
        })
        // .then((res) => console.log(res))
        .then((res) => { selectFormData(res) })
    }

    const delPart = async(partId) => {
        // console.log(partId)

        axios.delete(`${process.env.REACT_APP_API_PARTS}`, {
            data: { partId: partId }
        })
    }

    return (
        <div style={{ width: "400px", height: "1400px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Product List</h1>
                <button style={{ height: "40px", marginLeft: "16px" }} onClick={ () => setRefresh(!refresh) }>refresh</button>
            </div>
            <div style={{ height: "1280px", overflowY: "scroll" }}>
                {
                    partDatas.map((data, i) => {
                        return (
                            <div key={i} style={{ marginBottom: "8px", display: "flex", justifyContent: "space-between"}}>
                                <div onClick={ () => { selectProduct(data._id) }}>{ data.product_name }</div>
                                <button onClick={ () => { delPart(data._id) }}>X</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AllProductList