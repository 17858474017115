import './App.css';
import Header from "./components/Header"
import Estimate from './pages/Estimate';
import GoogleAdsense from './components/GoogleAdsense';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PartDataManager from './pages/PartDataManager';

function App() {
  return (
    <div className="container">
      <BrowserRouter>
        <Header/>
        {/* <GoogleAdsense /> */}
        <div className="main-content">
          <Routes>
            <Route path="/" element={ <Estimate/> }/>
            <Route path="/part-data-manager" element= { <PartDataManager/> }/>
          </Routes>
        </div>
        {
          // isMobile
          // ? <></>
          // : <GoogleAdsense />
        }
        <div className="footer">
          <p>Contact(insta) : @bif_.official</p>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
