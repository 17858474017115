import "./Pages.css"
import Filter from "../components/estimate-components/Filter"
import BikeSelect from "../components/estimate-components/BikeSelect";
import BikeResult from "../components/estimate-components/BikeResult";
import PurchaseList from "../components/estimate-components/PurchaseList";
import Price from "../components/estimate-components/Price";
import Final from "../components/estimate-components/Final";
import ProductList from "../components/estimate-components/ProductList";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import MobileEstimateHandler from "../components/estimate-components/MobileEstimateHandler";
import { debounce } from "lodash"
import axios from "axios";

const Estimate = () => {
  // constant
  const product_basic_info = {
    "id": "",
    "img_src": "",
    "product_name": "",
    "product_price": null,
    "seatpost_position": 31.5,
    "stem_Xposition": 67,
    "stem_Yposition": 12
  }
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const custom_query = searchParams.get("custom")

  // state
  // selected part
  const [partSelect, setPartSelect] = useState("wheelset")
  // selected product info
  const [productSelect, setProductSelect] = useState(product_basic_info)
  // selected products info
  const [selectedList, setSelectedList] = useState({
    "front_wheel": product_basic_info,
    "rear_wheel": product_basic_info,
    "frame": product_basic_info,
    "seatpost": product_basic_info,
    "saddle": product_basic_info,
    "stem": product_basic_info,
    "handlebar": product_basic_info,
    "chainring": product_basic_info,
    "crank": product_basic_info
  })
  // part select cancel
  const [cancelSelect, setCancelSelect] = useState("")
  // front rear divide
  const [isDivide, setIsDivide] = useState(false)
  // search keyword
  const [searchKeyword, setSearchKeyword] = useState("")
  // mobile & browser design divide
  const [width, setWidth] = useState(window.innerWidth);

  // function
  const getPartSelect = (newPartSelect) => {
    setPartSelect(newPartSelect)
  }
  const getProductSelect = (newProductSelect) => {
    setProductSelect(newProductSelect)
  }
  const getCancelSelect = (newCancelSelect) => {
    setCancelSelect(newCancelSelect)

    // selected product cancel
    setSelectedList(() => {
      if (newCancelSelect === "wheelset") {
        return {
          ...selectedList,
          "front_wheel": product_basic_info,
          "rear_wheel": product_basic_info
        }
      } else {
        return {
          ...selectedList,
          [newCancelSelect]: product_basic_info
        }
      }
    })
  }
  const getIsDivide = () => {
    setIsDivide(!isDivide)
  }
  // browser width event
  const isMobile = width < 768;
  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
  }, 200);

  // effect
  useEffect(() => {
    // console.log(productSelect)

    if (partSelect === "wheelset") {
      setSelectedList({
        ...selectedList,
        "front_wheel": productSelect,
        "rear_wheel": productSelect
      })
    } else {
      setSelectedList({
        ...selectedList,
        [partSelect]: productSelect
      })
    }

    // console.log(selectedList)
  }, [productSelect])
  // front rear divide
  useEffect(() => {
    // console.log(isDivide)

    if (isDivide === true) {
      setPartSelect("front_wheel")
    } else {
      setPartSelect("wheelset")
    }
  }, [isDivide])
  // mobile & browser divide event
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // cleanup
    };
  }, []);
  useEffect(() => {
    if (custom_query) {
      const decode_data = JSON.parse(atob(custom_query))
      const filtered_data = decode_data.filter(item => item !== "");
      let res_data = {}

      const getPartById = async(partId, isFrontWheel = false) => {
        return new Promise((resolve, reject) => {
          axios
            .get(`${process.env.REACT_APP_API_PARTS}/one`, {
              params: { partId: partId },
            })
            .then((res) => {
              if (res.data.part === "wheelset") {
                if (isFrontWheel) {
                  res_data["front_wheel"] = { ...product_basic_info, ...res.data, id: res.data._id };
                } else {
                  res_data["rear_wheel"] = { ...product_basic_info, ...res.data, id: res.data._id };
                }
              } else {
                res_data[res.data.part] = { ...product_basic_info, ...res.data, id: res.data._id };
              }
              resolve(); // 요청이 성공적으로 끝난 경우 resolve
            })
            .catch((error) => {
              console.error(error);
              reject(error); // 에러가 발생한 경우 reject
            });
        });
      }

      const main = async() => {
        try {
          // 프론트 휠과 리어 휠이 같은 partId에서 오는 경우라면 따로 요청 처리
          await getPartById(filtered_data[0], true); // 첫 번째 요청은 프론트 휠
          await getPartById(filtered_data[0], false); // 두 번째 요청은 리어 휠
          
          // 나머지 파츠에 대해 요청
          const otherParts = filtered_data.slice(1).map(partId => getPartById(partId));
          await Promise.all(otherParts);
    
          // 모든 데이터를 다 모은 후에 selectedList 업데이트
          if (res_data.front_wheel.id !== res_data.rear_wheel.id) getIsDivide()
          setSelectedList({
            ...selectedList,
            ...res_data,
          }); 
          searchParams.delete("custom")
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        } catch (error) {
          console.error("Error in fetching parts:", error);
        }
      }

      main()
    }
  }, [custom_query])

  // display area
  if (isMobile) {
    return (
      <div className="estimate-body">
        <div className="sticky-header">
          <BikeResult
              selectedList={ selectedList }
              getPartSelect={ getPartSelect }
              isDivide={ isDivide }
          />
          <MobileEstimateHandler
            partSelect={ partSelect }
            selectedList={ selectedList }
            cancelSelect={ cancelSelect }
            getCancelSelect={ getCancelSelect }
            getIsDivide={ getIsDivide }
            isDivide={ isDivide }
            setSearchKeyword={ setSearchKeyword }
            isMobile={ isMobile }
          />
        </div>
        <ProductList
          partSelect={ partSelect }
          getProductSelect={ getProductSelect }
          searchKeyword={ searchKeyword }
          isMobile={ isMobile }
        />
      </div>
    )
  } else {
    return (
      <div className="estimate-body">
        <div className="status-body">
          <div className="top-status">
            <Filter
              partSelect={ partSelect }
              isDivide={ isDivide }
              getIsDivide={ getIsDivide }
            />
            {/* <BikeSelect/> */}
          </div>
          <BikeResult
            selectedList={ selectedList }
            getPartSelect={ getPartSelect }
            isDivide={ isDivide }
          />
          <div className="bottom-status">
            <PurchaseList
              selectedList={ selectedList }
              getCancelSelect={ getCancelSelect }
              isMobile={ isMobile }
              isDivide={ isDivide }
            />
            <Price
              selectedList={ selectedList }
              isDivide={ isDivide }
            />
            <Final/>
          </div>
        </div>
        <div className="product-body">
          <ProductList
            partSelect={ partSelect }
            getProductSelect={ getProductSelect }
            searchKeyword={ searchKeyword }
            setSearchKeyword={ setSearchKeyword }
            isMobile={ isMobile }
          />
        </div>
      </div>
    )
  }
}

export default Estimate