import React from "react";

const SearchBar = ({ setSearchKeyword }) => {
    const onChange = (e) => {
        setSearchKeyword(e.target.value)
    }

    return (
        <div className="product-search">
            <input type="text" placeholder="검색어를 입력해주세요" onChange={ onChange }/>
        </div>
    )
}

export default SearchBar